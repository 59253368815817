import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Modal } from '@components/Modal'
import { Typography } from '@components/Typography'

import { intro } from '@root/utils/routes'
import { AUTH_LOCAL_STORAGE_KEY } from '@root/api/core/request'
import { useConfig } from '@root/Context'

type Props = {
  type?: 'session' | 'appointment' | 'country'
  onClose?: () => void
}

const StyledContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};

  padding: 61px;
`

const SessionExpired: React.FC<Props> = ({ type = 'session', onClose }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { country } = useConfig()

  const handleClose = () => {
    history.push(intro())
    onClose?.()
  }

  useEffect(() => {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  }, [])

  const errorPath = type === 'country' ? `${type}${country}` : type

  return (
    <Modal
      isOpen
      showCloseButton
      closeButtonType="text"
      onRequestClose={handleClose}
    >
      <StyledContainer>
        <Typography
          $variant="h5"
          $noMargin
          $align="left"
          dangerouslySetInnerHTML={{ __html: t(`anz.otp.${errorPath}Expired`) }}
        />
      </StyledContainer>
    </Modal>
  )
}

export default SessionExpired
