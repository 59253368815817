import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Typography } from '@components/Typography'

import OTPInput from './OTPInput'
import ModalStyle from './OTPModalStyling'
import { OtpWithMode } from '@root/api/models/Otp'
import { OtpService } from '@root/api/services/OtpService'
import { useConfig } from '@root/Context'
import { anzIntro } from '@root/utils/routes'
import { useSessionJwt } from '@hooks/useSessionJwt'

type Props = {
  otpOptions: OtpWithMode | undefined
  navigateToStepOne: () => void
}

const MAX_OTP_TESTS = 3

const StepTwo: React.FC<Props> = ({ otpOptions, navigateToStepOne }) => {
  const { t } = useTranslation()

  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
  } = useConfig()

  const otpServiceParams = useMemo(
    () => ({
      brand,
      appointmentId,
      ocpApimSubscriptionKey,
      caller,
    }),
    [brand, appointmentId, ocpApimSubscriptionKey, caller]
  )

  const history = useHistory()

  const { setSessionJwt } = useSessionJwt()

  const [isTimerExpired, setIsTimerExpired] = useState<boolean>(false)
  const { minutes, seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => setIsTimerExpired(true),
  })

  const [isOTPExpired, setIsOTPExpired] = useState<boolean>(false)
  const [isOTPError, setIsOTPError] = useState<number>(0)

  const [otpTests, setOtpTests] = useState<number>(0)

  const handleOTPInsertion = async (otp: string) => {
    const result = await OtpService.checkOtp({
      ...otpServiceParams,
      otp,
    })

    if (result.success && result.jwt) {
      setIsOTPError(-1)
      setSessionJwt(result.jwt)
      history.push(anzIntro())
      return
    }

    const isExpired = result.errorCode === 'otp.expired'
    setIsOTPExpired(isExpired)
    // We love magic numbers .. right? :D
    // 0 -> no error
    // 1 -> error
    // -1 -> success (SURPRISE!)
    // A C language developer was here :D
    // I inherited this code, I swear
    setIsOTPError(isExpired ? 0 : 1)
    setOtpTests(isExpired ? 1 : otpTests + 1)
  }

  const handleOTPInvalidation = useCallback(async () => {
    const result = await OtpService.invalidateOtp({
      ...otpServiceParams,
    })

    if (result === 'OTP invalidated') {
      navigateToStepOne()
    }
  }, [navigateToStepOne, otpServiceParams])

  const handleTimer = (timeBeforeRecreate: number) => {
    const time = new Date()
    time.setSeconds(time.getSeconds() + timeBeforeRecreate)
    restart(time)
  }

  useEffect(() => {
    handleTimer(otpOptions?.timeBeforeRecreate || 120)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpOptions])

  useEffect(() => {
    if (otpTests === MAX_OTP_TESTS) {
      void handleOTPInvalidation()
    }
  }, [handleOTPInvalidation, otpTests])

  return (
    <ModalStyle.StyledContent>
      <ModalStyle.StyledTypographyWithMarginBottom
        $variant="h1"
        $weight={700}
        $align="left"
      >
        {t('anz.otp.stepTwo.heading')}
      </ModalStyle.StyledTypographyWithMarginBottom>
      <ModalStyle.StyledTypographyWithMarginBottom $align="left">
        {t('anz.otp.stepTwo.description', {
          contact:
            otpOptions?.type === 'mail' ? otpOptions?.email : otpOptions?.phone,
        })}

        {isOTPError === 1 ? (
          <Typography $color="error" $align="left">
            {t('anz.otp.stepTwo.error')}
          </Typography>
        ) : null}
        {isOTPExpired ? (
          <Typography $color="error" $align="left">
            {t('anz.otp.stepTwo.expired')}
          </Typography>
        ) : null}
      </ModalStyle.StyledTypographyWithMarginBottom>
      <OTPInput
        onOTPEnd={handleOTPInsertion}
        isError={isOTPError === 1 || isOTPExpired}
        isSuccess={isOTPError === -1}
      />
      <ModalStyle.StyledFooter>
        <Typography $noMargin $align="left">
          {t('anz.otp.stepTwo.didntReceive')}
        </Typography>
        {isTimerExpired ? (
          <ModalStyle.StyledUnderlinedButton onClick={navigateToStepOne}>
            {t('anz.otp.stepTwo.resendCta')}
          </ModalStyle.StyledUnderlinedButton>
        ) : (
          <Typography $noMargin $align="left">
            {t('anz.otp.stepTwo.resendCountdown', { minutes, seconds })}
          </Typography>
        )}

        <Typography $align="left" $margin="20px 0 0 0">
          {t('anz.otp.stepTwo.storeCTA', {
            name: otpOptions?.storeName || '',
            phone: otpOptions?.storePhone || '',
          })}
        </Typography>
      </ModalStyle.StyledFooter>
    </ModalStyle.StyledContent>
  )
}

export default StepTwo
