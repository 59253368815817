import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CensoredContacts, OtpSystem, OtpWithMode } from '@root/api/models/Otp'
import { OtpService } from '@root/api/services/OtpService'
import { useConfig } from '@root/Context'

import ModalStyle from './OTPModalStyling'
import { Typography } from '@components/Typography'
import { Loader } from '@components/Loader'

import emailIcon from '../../assets/icons/email.svg'
import arrowIcon from '../../assets/icons/arrow.svg'
import smsIcon from '../../assets/icons/sms.svg'
import React, { useState } from 'react'
import { useGetCurrentLanguage } from '@hooks/useLanguage'

type StepOneProps = {
  navigateToStepTwo: (otpResponse: OtpWithMode) => void
  contacts: CensoredContacts | undefined
  isLoading: boolean
}

const StyledTargetsWrapper = styled.div`
  display: grid;
  gap: 15px;
`

const StyledTargetButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  margin: 0;
  border: none;
  padding: 1rem;
  background-color: inherit;

  cursor: pointer;

  @media (prefers-color-scheme: dark) {
    img {
      filter: invert(100%);
    }
  }
`

const StyledArrowIcon = styled.img.attrs({
  src: arrowIcon,
  alt: 'Arrow icon',
})`
  transform: rotate(90deg);
  margin-inline-start: auto;
`

const SendToSMSOrEmail = ({
  type,
  navigateToStepTwo,
  contact,
  handleLoading,
  handleError,
}: Pick<StepOneProps, 'navigateToStepTwo'> & {
  type: OtpSystem
  contact: string | undefined
  handleLoading: (status: boolean) => void
  handleError: (status: boolean) => void
}) => {
  const { t } = useTranslation()

  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
  } = useConfig()
  const currentLanguage = useGetCurrentLanguage()

  const handleClick = async () => {
    handleError(false)
    handleLoading(true)
    try {
      const result = await OtpService.generateOtp({
        appointmentId,
        brand,
        caller,
        ocpApimSubscriptionKey,
        acceptedLanguage: currentLanguage,
        system: type,
      })
      navigateToStepTwo({ ...result, type })
    } catch (error) {
      handleError(true)
    } finally {
      handleLoading(false)
    }
  }

  const targetIcon = type === 'sms' ? smsIcon : emailIcon

  const targetAlt = type === 'sms' ? 'Sms icon' : 'Email icon'

  const targetLabel =
    type === 'sms'
      ? t(`anz.otp.stepOne.sms`, { contact })
      : `${t('anz.otp.stepOne.mail')} ${contact}`

  return (
    <StyledTargetButton onClick={handleClick}>
      <img src={targetIcon} alt={targetAlt} />
      <Typography
        $align="left"
        $noMargin
        $variant={'span'}
        $wordBreak={'break-all'}
      >
        {targetLabel}
      </Typography>
      <StyledArrowIcon />
    </StyledTargetButton>
  )
}

const StepOne = ({ navigateToStepTwo, isLoading, contacts }: StepOneProps) => {
  const { t } = useTranslation()

  const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)

  return (
    <ModalStyle.StyledContent>
      <ModalStyle.StyledTypographyWithMarginBottom
        $variant="h1"
        $weight={700}
        $align="left"
      >
        {t('anz.otp.stepOne.heading')}
      </ModalStyle.StyledTypographyWithMarginBottom>
      <ModalStyle.StyledTypographyWithMarginBottom $align="left">
        {t('anz.otp.stepOne.description')}
      </ModalStyle.StyledTypographyWithMarginBottom>
      {!isLoading ? (
        isLoadingResponse ? (
          <Loader height={200} />
        ) : (
          <StyledTargetsWrapper>
            {contacts && contacts?.phone ? (
              <SendToSMSOrEmail
                type="sms"
                contact={contacts?.phone}
                handleError={setHasError}
                handleLoading={setIsLoadingResponse}
                navigateToStepTwo={navigateToStepTwo}
              />
            ) : null}
            {contacts && contacts?.email ? (
              <SendToSMSOrEmail
                type="mail"
                contact={contacts?.email}
                handleError={setHasError}
                handleLoading={setIsLoadingResponse}
                navigateToStepTwo={navigateToStepTwo}
              />
            ) : null}
            {hasError ? (
              <Typography $color="error" $align="center" $noMargin>
                {`An error occurred while sending the notification.\nPlease try again later.`}
              </Typography>
            ) : null}
          </StyledTargetsWrapper>
        )
      ) : null}
    </ModalStyle.StyledContent>
  )
}

export default StepOne
