import React, { useContext, useEffect } from 'react'
import { WidgetProps } from '@rjsf/core'

import { TextField } from '@components/TextField'
// import { handleOnChange } from '../utils/handleOnChange'
import { FormDataContext } from '@root/anz/components/AnzForm/JSONSchemaForm'
import { differenceInYears } from 'date-fns'

const parseAgeValue = (value: string) => {
  const now = new Date()
  const dob = new Date(value)
  const diff = differenceInYears(
    new Date(now.getFullYear(), now.getMonth(), now.getDay()),
    new Date(dob.getFullYear(), dob.getMonth(), dob.getDay())
  )

  return diff >= 0 ? diff.toString() : ''
}

const AgeWidget = ({
  disabled,
  label,
  required,
  value,
  onChange,
  id,
  rawErrors,
  ...props
}: React.PropsWithChildren<WidgetProps>) => {
  const formData = useContext(FormDataContext)
  const currentValue = parseAgeValue((formData.dob as string) || '') || '/'

  useEffect(() => {
    !!currentValue && onChange(currentValue)
  }, [onChange, currentValue])

  return (
    <TextField
      name={id}
      value={parseAgeValue((formData.dob as string) || '') || '/'}
      label={label}
      disabled={true}
      required={required}
      $fullWidth
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.join('\n')}
      minLength={props.schema.minLength}
      maxLength={props.schema.maxLength}
    />
  )
}

export default AgeWidget
