import { AUTH_LOCAL_STORAGE_KEY } from '@root/api/core/request'

export const useSessionJwt = (): {
  sessionJwt: string
  setSessionJwt: (jwt: string) => void
} => {
  const sessionJwt = window.sessionStorage.getItem(AUTH_LOCAL_STORAGE_KEY) ?? ''

  const setSessionJwt = (jwt: string) => {
    window.sessionStorage.setItem(AUTH_LOCAL_STORAGE_KEY, jwt)
  }

  return {
    sessionJwt,
    setSessionJwt,
  }
}
