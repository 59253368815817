// #region ::: IMPORTS
import React, {
  createContext,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react'
// LIBRARIES
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'

// UTILS
import { useFormComplete } from '@hooks/useFormComplete'
import { DynamicAnzFormProvider } from '@root/services/DynamicAnzFormProvider'
import { intro, steps, useFormStatus } from '@root/utils/routes'

// COMPONENTS
import { Button } from '@components/Button'
import { Loader } from '@components/Loader'
import { Modal } from '@components/Modal'
import { Typography } from '@components/Typography'
import { ReviewPage } from '../ReviewPage'
import JSONSchemaForm from './JSONSchemaForm'

//TYPES
import type { AnzForm as AnzFormType } from '@api'
import { AppointmentRecap } from '@components/AppointmentRecap'
import { Layout } from '@components/Layout'
import { StepLayout } from '@components/StepLayout'
import useWindowSizeDebounced from '@hooks/useWindowSizeDebounced'
import { useTabApiForm } from '@root/services/TabApiProvider/hooks/useTabApiForm'
import { useTabApiJsonSchema } from '@root/services/TabApiProvider/ProviderDynamicForm'
import { useFormMode } from '@hooks/useFormMode'
import { getHideLogo } from '@root/utils/logo'
import { useConfig } from '@root/Context'
import useIsFormCompleted from '@hooks/useIsFormCompleted'
import { isCaOrUs } from '@root/utils/utils'
import usePageClassname from '@hooks/usePageClassname'

export const FormIdContext = createContext('')

// #endregion

const StyledContainer = styled.div`
  padding: 3rem 2.25rem;
`

const TwoColumns = styled.div`
  position: relative;
  width: 100%;

  & > * + * {
    margin-bottom: 40px;
  }
`

const StyledFormErrorWrapper = styled.div`
  span:first-child {
    margin-right: 0.5rem;
  }
`

const StyledContinueButton = styled(Button)`
  &[data-can-submit='false'] {
    cursor: not-allowed;
  }

  &[hidden] {
    display: none;
  }
`

const FORM_COMPLETED_VALUE = 10

export const AnzForm = () => {
  usePageClassname('form-page')
  const { t } = useTranslation()
  const history = useHistory<{ step?: number }>()
  const { isFormBilled, isFormExpired, isFormArrived } = useFormStatus()
  const { isReadOnly } = useFormMode()

  const [atStep, setAtStep] = useState<number>(0)
  const [prevStepsCount, setPrevStepsCount] = useState<number>(0)
  const [nextStepsCount, setNextStepsCount] = useState<number>(0)
  const [isAtReview, setIsAtReview] = useState<boolean>(false)
  const [isBack, setIsBack] = useState<boolean>(false)
  const [hasValidationErrors, setHasValidationErrors] = useState<boolean>(false)
  const [hasTriedSubmitting, setHasTriedSubmitting] = useState<boolean>(false)

  const windowSize = useWindowSizeDebounced()
  const stepLayoutRef = useRef<HTMLDivElement>(null)
  const stepLayoutHeight =
    stepLayoutRef.current?.getBoundingClientRect().height ?? 0

  const { data, isPending: isPendingJsonSchema } = useTabApiJsonSchema()
  const {
    data: formData,
    run,
    isPending: isPendingForm,
  } = useTabApiForm<AnzFormType>()
  const { questionAnswer, ...formInfo } = formData || {}
  const [localFormData, setLocalFormData] = useState<
    AnzFormType['questionAnswer']
  >(questionAnswer || {})
  const formDataToSubmit = formData
    ? {
        ...formData,
        questionAnswer: localFormData,
      }
    : undefined

  const [redirectTo, setRedirectTo] = useState<'CONFIRMATION' | undefined>(
    undefined
  )

  const handleFormComplete = useFormComplete({
    data: formDataToSubmit,
    nextStep: 'COMPLETED',
    run,
    isBack,
    redirectTo,
  })
  const { isFormCompleted } = useIsFormCompleted(formData)

  const { country, isDoctor } = useConfig()

  const isCaOrUsCountry = isCaOrUs(country)

  if ('step' in formInfo && !!steps[atStep]) {
    formInfo.step = steps[atStep]
  }

  const stepsLength = data?.length || 0

  const lastStepNumber = !stepsLength ? 0 : stepsLength - 1

  const [completionValue, setCompletionValue] = useState<number | undefined>(
    undefined
  )

  const showContinueLater =
    completionValue !== undefined && completionValue < FORM_COMPLETED_VALUE

  const uiSchema = { ...data?.[atStep]?.uiSchema, 'ui:hideError': true }

  const isUnauthorized =
    localFormData?.populateForMyself === 'Someone else' &&
    localFormData?.imAuthorized === 'No'

  const isFormBiggerThanWindow = stepLayoutHeight > windowSize.height - 150

  const removeAuthorization = () =>
    setLocalFormData((formData) => ({
      ...formData,
      imAuthorized: undefined,
    }))

  const handleSteps = (shouldGoToNextStep: boolean) => {
    setAtStep((currentStep) => {
      const previousStep = currentStep !== 0 ? currentStep - 1 : currentStep
      const nextStep =
        currentStep !== lastStepNumber ? currentStep + 1 : currentStep
      return shouldGoToNextStep ? nextStep : previousStep
    })
  }

  const handleBackClick = () => {
    if (isCaOrUsCountry) {
      questionAnswer && setLocalFormData(questionAnswer)
      atStep === 0 && history.push(intro())
    }
    setIsBack(true)
    setIsAtReview(false)
    handleSteps(false)
  }

  const goToStep = (step: number) => {
    if (data) {
      setAtStep(step)
      setIsAtReview(false)
    }
  }

  const handleSubmit = (hasErrors: boolean) => {
    setIsBack(false)
    setRedirectTo(undefined)
    setHasValidationErrors(hasErrors)
    setHasTriedSubmitting(hasErrors)
    if (hasErrors) return
    handleSteps(true)
    if (prevStepsCount && nextStepsCount <= 0) {
      setIsAtReview(true)
    }
  }

  const handleContinueClick = () => {
    isAtReview && isFormCompleted && !isDoctor && setRedirectTo('CONFIRMATION')
  }

  useEffect(() => {
    redirectTo && handleFormComplete()
  }, [redirectTo])

  useEffect(() => {
    const shouldSetAtReview =
      prevStepsCount === 0 && nextStepsCount === 0 && isFormCompleted
    shouldSetAtReview && setIsAtReview(true)
  }, [prevStepsCount, nextStepsCount, isFormCompleted])

  useEffect(() => {
    const shouldSetAtStep = isFormCompleted && isAtReview
    shouldSetAtStep && setAtStep(lastStepNumber + 1)
  }, [isFormCompleted, isAtReview, lastStepNumber])

  useEffect(() => {
    const shouldHandleFormComplete =
      isAtReview && !isFormCompleted && !isReadOnly
    shouldHandleFormComplete && void handleFormComplete()
  }, [isFormCompleted, isAtReview, isReadOnly])

  useEffect(() => {
    // the intro section with PP documents it's not a step but should be counted as one
    // same for the review page, so we add 2 to the lastStepNumber
    // and we add 1 to the atStep because the first step is 0
    setCompletionValue(+(((atStep + 1) / (lastStepNumber + 2)) * 10).toFixed(1))
  }, [atStep, lastStepNumber])

  useEffect(() => {
    setPrevStepsCount(isAtReview ? 0 : atStep + 1)
    setNextStepsCount(isAtReview ? 0 : lastStepNumber - atStep)
  }, [atStep, isAtReview, lastStepNumber])

  return (
    <DynamicAnzFormProvider goToStep={goToStep}>
      <FormIdContext.Provider value={formData?.id || ''}>
        <Layout>
          <Layout.Header
            showContinueLaterButton={showContinueLater}
            continueLaterDataElementId="X_OEE_Review_Continue-Later "
            showCompletionTracker={stepsLength > 0}
            completionTrackerValue={completionValue}
            hideLogo={getHideLogo(country)}
          />

          <Layout.Content>
            <TwoColumns>
              {isAtReview ? (
                <ReviewPage />
              ) : (
                <StepLayout ref={stepLayoutRef}>
                  {isPendingJsonSchema || isPendingForm ? (
                    <Loader height={200} />
                  ) : null}
                  {data && !isPendingForm ? (
                    <JSONSchemaForm
                      disabled={isFormBilled || isFormArrived || isFormExpired}
                      jsonSchema={data[atStep].jsonSchema}
                      uiSchema={uiSchema}
                      formData={localFormData}
                      formInfo={formInfo as Omit<AnzFormType, 'questionAnswer'>}
                      atStep={atStep}
                      setFormData={setLocalFormData}
                      onChange={setHasValidationErrors}
                      onSubmit={handleSubmit}
                    />
                  ) : null}
                </StepLayout>
              )}
            </TwoColumns>

            <AppointmentRecap />
          </Layout.Content>

          {
            <Layout.Footer>
              <Button onClick={handleBackClick} $outlined $compact>
                {t('core.back')}
              </Button>
              <StyledFormErrorWrapper
                as={windowSize.width > 700 ? 'div' : Fragment}
              >
                {hasValidationErrors &&
                  hasTriedSubmitting &&
                  isFormBiggerThanWindow && (
                    <Typography $color="error" $variant="span" $weight="bold">
                      {t('core.incorrectForm')}
                    </Typography>
                  )}
                <StyledContinueButton
                  type="submit"
                  form="theForm"
                  disabled={isPendingJsonSchema || isPendingForm}
                  data-can-submit={!hasValidationErrors}
                  hidden={isAtReview && isDoctor}
                  onClick={handleContinueClick}
                  $compact
                >
                  {t('core.continue')}
                </StyledContinueButton>
              </StyledFormErrorWrapper>
            </Layout.Footer>
          }
        </Layout>

        <Modal
          isOpen={isUnauthorized}
          showCloseButton
          onRequestClose={removeAuthorization}
        >
          <Modal.Content>
            <StyledContainer>
              <Typography $variant="h1">
                {t('anz.sessionUnauthorized')}
              </Typography>
            </StyledContainer>
          </Modal.Content>
        </Modal>
      </FormIdContext.Provider>
    </DynamicAnzFormProvider>
  )
}
