import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

interface Props {
  otpLength?: number
  onOTPEnd: (otp: string) => void
  isError?: boolean
  isSuccess?: boolean
}

const StyledOTPContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
`

const StyledDigitInput = styled.input<{
  isError?: boolean
  isSuccess?: boolean
}>`
  padding: 0;

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }

  border: none;
  border-bottom: 1.5px solid
    ${({ isError, isSuccess }) =>
      isError
        ? '#E1523E'
        : isSuccess
        ? '#3F8350'
        : ({ theme }) => theme.colors.onSurface};

  width: 1.5rem;
  height: 36px;

  background: transparent;
  color: ${({ theme }) => theme.colors.onSurface};

  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;

  /* Experimental: could cause issues accessibility sides */
  outline: none;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      width: 1.75rem;
      height: 42px;
    }
    ${theme.breakpoints.media.medium} {
      width: 2.25rem;
      height: 55px;
    }
  `}
`

const OTPInput: React.FC<Props> = ({
  onOTPEnd,
  otpLength = 6,
  isError,
  isSuccess,
}) => {
  const [otpCode, setOTPCode] = useState<string[]>(
    new Array(otpLength).fill('')
  )
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const otpRef = useRef<HTMLInputElement>(null)

  const handleChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const newOTP: string[] = [...otpCode]
      newOTP[index] = value.substring(value.length - 1)

      if (!value) setActiveIndex(index === 0 ? index : index - 1)
      else if (!(index === otpCode.length - 1)) setActiveIndex(index + 1)

      setOTPCode(newOTP)
    }

  const handleKeyDown =
    (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Backspace':
        case 'ArrowLeft':
          setActiveIndex(index - 1)
          break
        case 'ArrowRight':
          setActiveIndex(index + 1)
          return
      }
    }

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    const copiedData = e.clipboardData.getData('text')

    const arrayData = copiedData.split('').slice(0, 6)

    const arrayDataWithPadEnd = arrayData.concat(
      new Array(otpLength - arrayData.length).fill('')
    )

    setOTPCode(arrayDataWithPadEnd)
    setActiveIndex(arrayData.length)
  }

  useEffect(() => {
    otpRef.current?.focus()
  }, [activeIndex])

  useEffect(() => {
    const otpString = otpCode.join('')
    if (otpString.length === otpLength) {
      onOTPEnd(otpString)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpCode])

  return (
    <StyledOTPContainer onPaste={handlePaste}>
      {otpCode.map((codeDigit, index) => (
        <React.Fragment key={`${codeDigit}_${index}`}>
          <StyledDigitInput
            ref={index === activeIndex ? otpRef : null}
            type="number"
            onChange={handleChange(index)}
            onKeyDown={handleKeyDown(index)}
            value={otpCode[index]}
            isError={isError}
            isSuccess={isSuccess}
          />
        </React.Fragment>
      ))}
    </StyledOTPContainer>
  )
}

export default OTPInput
