import React, { useCallback, useState } from 'react'
import { useAsync } from 'react-async'
import styled, { css } from 'styled-components'

import { OtpWithMode } from '@root/api/models/Otp'
import { useConfig } from '@root/Context'
import { OtpService } from '@root/api/services/OtpService'
import { SessionExpired } from '@root/routes/OPSM'

import { Modal } from '@components/Modal'
import ModalStyle from './OTPModalStyling'

import OPSMIcon from '../../assets/icons/opsm-logo.svg'

import StepOne from './StepOne'
import StepTwo from './StepTwo'
import { useGetCurrentLanguage } from '@hooks/useLanguage'
import { isCaOrUs } from '@root/utils/utils'

const StyledContainer = styled.div`
  padding: 3rem 1.25rem;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      padding: 3rem 1.75rem;
    }
    ${theme.breakpoints.media.medium} {
      padding: 3rem 2.25rem;
    }
  `}
`

const OTPModal: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [otpSentOptions, setOtpSentOptions] = useState<OtpWithMode>()

  const {
    brand,
    appointmentId,
    caller,
    subscriptionKey: ocpApimSubscriptionKey,
    country,
  } = useConfig()
  const currentLanguage = useGetCurrentLanguage()

  const getContacts = useCallback(
    () =>
      OtpService.getCensoredContacts({
        brand,
        appointmentId,
        ocpApimSubscriptionKey,
        caller,
        acceptedLanguage: currentLanguage,
      }),
    [appointmentId, brand, caller, currentLanguage, ocpApimSubscriptionKey]
  )

  const { isPending, data, error } = useAsync(getContacts)

  const navigateToStepOne = () => setActiveStep(0)

  const navigateToStepTwo = (otpResponse: OtpWithMode) => {
    setOtpSentOptions(otpResponse)
    setActiveStep(1)
  }

  if (!isCaOrUs(country) && error) {
    return <SessionExpired type="country" />
  }

  return (
    <Modal isOpen>
      <StyledContainer>
        {!isCaOrUs(country) ? (
          <ModalStyle.StyledHeader>
            <img src={OPSMIcon} alt="Logo" />
          </ModalStyle.StyledHeader>
        ) : null}
        {activeStep === 0 ? (
          <StepOne
            navigateToStepTwo={navigateToStepTwo}
            contacts={data}
            isLoading={isPending}
          />
        ) : null}
        {activeStep === 1 ? (
          <StepTwo
            otpOptions={otpSentOptions}
            navigateToStepOne={navigateToStepOne}
          />
        ) : null}
      </StyledContainer>
    </Modal>
  )
}

export default OTPModal
