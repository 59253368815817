import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Props as ReactModalProps } from 'react-modal'
import styled, { css, useTheme } from 'styled-components'
import { useHistory, useParams } from 'react-router'
import { useMedia } from 'react-use'

import { Layout } from '@components/Layout'
import { Button } from '@components/Button'
import { Signature } from '@components/Signature'
import { Typography } from '@components/Typography'
import { Icon } from '@components/Icon'
import ArrowIcon from '@assets/icons/arrow.svg'
import {
  flatFormObjectIfAnz,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'
import { htmlToReactComponent } from '@utils/htmlToReact'
import { anzForm, intro } from '@utils/routes'
import { Loader } from '@components/Loader'
import usePageClassname from '@hooks/usePageClassname'
import { DocumentType, FormService, OptIn } from '@api'
import { useTabApiAppointment } from '../services/TabApiProvider/ProviderAppointment'
import type { DocumentToSave } from '../api/models/Document'
import { useLegalDocument } from '@root/services/tabApi'
import { useConfig } from '@root/Context'
import { getHideLogo } from '@root/utils/logo'
import { PrivacyPracticeContext } from '@root/services/TabApiProvider/ProviderAnzForm'
import { DocumentRecap } from '@root/api/models/DocumentRecap'

interface LegalDocumentProps {
  backAction: ReactModalProps['onRequestClose']
  docType: DocumentType
  isDocumentsSigned: boolean
  recapDocumentsSigned: DocumentRecap[]
}

interface BackButtonProps {
  isDesktop: boolean
}

const BackButton = styled(Button)<BackButtonProps>`
  background-color: transparent;
  border-color: transparent;
  color: ${({ theme: { colors } }) => colors.onSurface};
  display: flex;
  align-items: center;
  max-width: 100%;
  ${({ isDesktop }) => !isDesktop && 'padding: 0'};

  &&&:not(disabled):hover,
  &&&:not(disabled):focus {
    box-shadow: unset;
  }
`

const BackButtonIcon = styled(Icon).attrs({
  src: ArrowIcon,
  className: 'intake__icon--arrow',
})`
  width: 25px;
  height: 25px;
  transform: rotate(-90deg);
`

const LayoutContent = styled(Layout.Content)`
  flex-direction: column !important;
  align-items: center;
`

const TitleWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundTertiary};
  display: flex;
  align-items: start;
  width: 100%;
  margin-top: 0 !important;
  padding-left: 1rem;
  font: ${({ theme: { fonts } }) => fonts.paragraph};
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${({ theme: { colors } }) => colors.onSurface};

  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      width: auto;
    }
  `}
`

const ProgressBarWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};
  width: 100%;
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: 100%;
  padding-left: 1rem;

  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      width: auto;
    }
  `}
`

const StyledProgress = styled.div`
  height: 8px;
  position: relative;
  background: ${({ theme: { colors } }) => colors.progressBg};
  border-radius: 25px;
  width: 100%;
  margin-right: 1rem;
`

const StyledSpan = styled.span<{ $value: string | number }>`
  display: block;
  height: 100%;
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.primary};
  position: relative;
  overflow: hidden;
  width: ${({ $value }) => `${+$value * 100}%`};
`

const StyledTextualProgress = styled(Typography)`
  width: 4rem;
`

const DocumentWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};
  width: 100%;
  margin-top: 0 !important;

  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      width: 100%;
    }
  `}
`

const DocumentContent = styled(Typography).attrs({
  as: 'div',
})`
  margin: auto;
  width: 100%;
  max-width: 1022px;
  padding: 10px;
  text-align: left;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      padding: 30px;
    }
  `}

  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      max-width: 450px;
      overflow: hidden;
    }
  `}

  iframe {
    width: 100%;
    height: 375px;
  }
`

const StyledPDFLink = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
  `}
  cursor: pointer;
  text-decoration: underline;
`

const base64ToBlob = (base64: string, type = 'application/octet-stream') => {
  const binStr = atob(base64)
  const len = binStr.length
  const arr = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i)
  }
  return new Blob([arr], { type: type })
}

export const LegalDocument: React.FC<LegalDocumentProps> = ({
  backAction,
  docType,
  isDocumentsSigned,
  recapDocumentsSigned,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ docType: DocumentType }>()
  const { data: formDataHook } = useTabApiForm()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [documentsToSave, setDocumentsToSave] = useState<DocumentToSave[]>([])
  const formData = flatFormObjectIfAnz(formDataHook)
  const { data: documentDataDocuments, isLoading: isLoadingDocument } =
    useLegalDocument(params.docType || docType!, formData?.id)
  const currentDocumentId =
    documentDataDocuments && documentDataDocuments.length
      ? documentDataDocuments[currentStep].documentId
      : ''
  const currentDocumentType =
    documentDataDocuments && documentDataDocuments.length
      ? documentDataDocuments[currentStep].docType
      : ''
  const documentData =
    documentDataDocuments && documentDataDocuments.length
      ? documentDataDocuments.find((d) => d.documentId === currentDocumentId)
      : undefined
  const { data: appointmentData } = useTabApiAppointment()
  const {
    breakpoints: { device },
  } = useTheme()
  const isDesktop = useMedia(device.large)

  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
    country,
  } = useConfig()

  const { setPrivacyPracticeDocs }: any = useContext(PrivacyPracticeContext)

  const hasLogo = !isDesktop || !!backAction || getHideLogo(country)
  const isDocumentPdf =
    documentData && documentData.content && documentData?.contentType === 'pdf'

  const handleCurrentStep = (document: DocumentToSave) => {
    if (
      documentDataDocuments &&
      currentStep < documentDataDocuments.length - 1
    ) {
      setCurrentStep(currentStep + 1)
    }
    if (documentDataDocuments && currentStep < documentDataDocuments.length) {
      setDocumentsToSave(documentsToSave.concat([{ ...document }]))
    }
  }

  const handleSignatureSubmission = async ({
    signature,
    signatureType,
    hasAccepted,
    event,
    isContinue,
    docType,
  }: {
    signature: string
    signatureType: 'DRAW' | 'TEXT'
    hasAccepted: boolean
    event: React.MouseEvent
    isContinue: boolean
    docType: string
  }) => {
    const date = new Date().toISOString()

    const newDocumentToSave: DocumentToSave = {
      hasAccepted,
      signature,
      signatureType,
      date,
      documentId: documentData ? documentData.documentId || '' : '',
      docType,
    }
    handleCurrentStep(newDocumentToSave)
    setPrivacyPracticeDocs(documentsToSave.concat([newDocumentToSave]))

    if (!isContinue) {
      await FormService.upsertPrivacyPolicyByAppId({
        brand,
        appointmentId,
        ocpApimSubscriptionKey,
        requestBody: documentsToSave.concat([newDocumentToSave]) as OptIn,
      })
    }

    if (
      documentDataDocuments &&
      currentStep + 1 === documentDataDocuments.length
    ) {
      // backAction ? backAction(event) : history.push(intro())
      history.push(anzForm())
    }

    // backAction ? backAction(event) : history.push(intro())
  }

  const backButtonHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => (backAction ? backAction(e) : history.push(intro()))

  const onRenderDocumentPdf = (): JSX.Element | null => {
    if (documentData && documentData?.content) {
      var pdfBase64 = documentData.content
      const pdfBlob = base64ToBlob(pdfBase64, 'application/pdf')
      const pdfUrl = URL.createObjectURL(pdfBlob)

      return (
        <>
          <iframe
            // src={`${documentData.content}#toolbar=0`}
            src={pdfUrl}
            title="privacy-practice"
          ></iframe>

          <StyledPDFLink
            className="intake__pdf-link"
            onClick={() => {
              window.open(pdfUrl)
            }}
          >
            {t('core.openInNewTab')}
          </StyledPDFLink>
        </>
      )
    } else return null
  }

  const onRenderDocumentText = (): JSX.Element | null => {
    if (documentData && documentData.content) {
      if (documentData.content.includes('#CLINIC_NAME#')) {
        const formattedDocumentContent = documentData.content.replace(
          '#CLINIC_NAME#',
          appointmentData?.clinic.name || ''
        )
        return htmlToReactComponent(formattedDocumentContent)
      } else {
        return htmlToReactComponent(documentData.content.trim())
      }
    } else return null
  }

  usePageClassname(`document-page`)

  if (isLoadingDocument) return <Loader fullHeight />

  const progressBarData = {
    length: documentDataDocuments ? documentDataDocuments.length : 0,
    currentStep:
      documentDataDocuments && documentDataDocuments.length
        ? documentDataDocuments.findIndex(
            (d) => d.documentId === currentDocumentId
          ) + 1
        : 0,
  }

  return (
    <Layout key={`document_${documentData?.documentId}`}>
      <Layout.Header hideLogo={hasLogo}>
        <BackButton onClick={backButtonHandler} isDesktop={isDesktop}>
          <BackButtonIcon />
          {t('core.back')}
        </BackButton>
      </Layout.Header>
      <TitleWrapper>{t('legalDocument.additionalFormsTitle')}</TitleWrapper>
      {documentDataDocuments && documentDataDocuments.length > 1 && (
        <ProgressBarWrapper>
          <StyledProgress>
            <StyledSpan
              $value={progressBarData.currentStep / progressBarData.length}
            />
          </StyledProgress>

          <StyledTextualProgress $color="primary">
            {`${progressBarData.currentStep} / ${progressBarData.length}`}
          </StyledTextualProgress>
        </ProgressBarWrapper>
      )}
      <LayoutContent>
        <DocumentWrapper>
          <DocumentContent>
            {isDocumentPdf ? onRenderDocumentPdf() : onRenderDocumentText()}
          </DocumentContent>
        </DocumentWrapper>
        <Signature
          onSubmit={handleSignatureSubmission}
          currentDocumentId={currentDocumentId || ''}
          currentDocumentType={currentDocumentType || ''}
          isDocumentsSigned={isDocumentsSigned}
          recapDocumentsSigned={recapDocumentsSigned}
        />
      </LayoutContent>
    </Layout>
  )
}
